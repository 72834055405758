import { imageUrls, pageUrls } from '@/config/constants/keys';
import { getImagePath, objectArrayFromDiffFields } from '@/utils/utils';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import React, { lazy, useState } from 'react';
import Filters from './Filters';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;
const Component = ({
  paginateList,
  hasFetchApiStatus,
  Styled,
  i18n,
  history,
  reportTypes,
  changeRequest,
  request,
  pagination,
  setPagination
}) => {
  const [values, setInitialValues] = useState({
    recordType: null
  });
  const reportTypesList = objectArrayFromDiffFields(
    reportTypes,
    'key',
    'displayName',
  );
  const columns = [
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Type'),
      needSorting: true
    },
    {
      id: 'requestDate',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Request Date (UTC)'),
      needSorting: true
    },
    {
      id: 'startDate',
      numeric: false,
      disablePadding: false,
      label: i18n.t('paymentInformation.start'),
      needSorting: true
    },
    {
      id: 'endDate',
      numeric: false,
      disablePadding: false,
      label: i18n.t('paymentInformation.end'),
      needSorting: true
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: i18n.t('Status'),
      needSorting: true
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Actions'),
      needSorting: false
    }
  ];

  const formatTime = (data, isUTC) => {
    const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let date = new Date(data);
    let year = isUTC ? date.getUTCFullYear() : date.getFullYear();
    let month = isUTC ? date.getUTCMonth() : date.getMonth();
    let day = ('0' + (isUTC ? date.getUTCDate() : date.getDate())).slice(-2);
    let hours = isUTC ? date.getUTCHours() : date.getHours();
    let minutes = isUTC ? date.getUTCMinutes() : date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = `${monthArray[month]} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
    return strTime;
  }

  const generateRows = (tableData) => {
    return tableData.map((item, index) => {
      return {
        ...item,
        type: reportTypesList.filter(ele => ele.value == item.type)[0]?.label || item.type,
        status: item.status?.charAt(0)?.toUpperCase() + item.status?.slice(1)?.toLowerCase(),
        requestDate: formatTime(item.requestDate, true),
        action: item.link ? <Icon
          src={getImagePath(imageUrls.downloadCenter)}
          onClick={() => {
            window.open(item.link, '_blank');
          }}
        /> : null
      };
    });
  };

  const information = hasFetchApiStatus?.errorMessage ? (
    <Message type="error" message={hasFetchApiStatus.errorMessage} />
  ) : (
    'common.tableEmptyMessage'
  );

  return (
    <Styled.Wrapper>
      <div className="quickFilter"></div>
      <div className="tableWrapper">
        <div className='filterWrapper'>
          <Filters
            reportTypesList={reportTypesList}
            filterList={(data) => {
              changeRequest(data)
            }}
            handleReset={() => {
              changeRequest({
                recordType: null
              })
            }}
            values={values}
            setInitialValues={setInitialValues}
          />
          <Button
            type="submit"
            label={i18n.t('Manage Scheduling')}
            onClick={(e, d) => {
              history.push(pageUrls?.manageScheduling);
            }}
          />
        </div>

        <Table
          type="server"
          defaultSortBy={{ sortBy: 'full_name', order: 'desc' }}
          title=""
          changeRequest={changeRequest}
          baseRequest={request}
          totalRecords={paginateList?.totalElements}
          columns={[...columns]}
          data={generateRows(paginateList?.content || [])}
          emptyMessage={information}
          itemsPerPage={15}
          pageinationReset={pagination}
          actions={[]}
          i18n={i18n}
          pagenationClicked={(e) => {
            setPagination(!e);
          }}
        />
      </div>
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  list: PropTypes.array.isRequired,
  hasFetchApiStatus: PropTypes.object.isRequired,
  Styled: PropTypes.object.isRequired,
  i18n: PropTypes.array.isRequired
};
