import { imageUrls, pageUrls } from '@/config/constants/keys';
import { getImagePath, isInsuracePortalUser } from '@/utils/utils';
import { Link as LinkLi } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { findAndDeleteParentInJson, hasEnabledFeature, hasEnabledSettings, } from '../../../utils/utils';
import Alerts from './Alerts';
import DriverBehavior from './DriverBehavior';
import DriverStatus from './DriverStatus';
import Notifications from './Notfications';
import Overview from './Overview';
import PotentialViolations from './PotentialViolations';
import RadiusComplianceAlerts from './RadiusComplianceAlerts';
import RecentAccidents from './RecentAccidents';

const layout = window?.$environment?.CURRENT_LAYOUT;
const GoogleMap = lazy(() => import(`@/components/GoogleMap/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Tab = lazy(() => import(`../../../components/UI/Tab/${layout}`));
const SvgIcon = lazy(() => import(`../../../components/SvgIcon/${layout}`));
const Modal = require(`../../../components/UI/Modal/${layout}`).default;
const CsaViolation = lazy(() => import(`./CSASnapshot`));

const Component = ({
  data,
  i18n,
  Styled,
  history,
  dashboard,
  viewReport,
  getDriverStatus,
  getVehicleStatus,
  callback,
  dataList,
  handleDeleteAlert
}) => {
  const insuracePortalUserRole = isInsuracePortalUser()
  useEffect(() => {
    if (!hasEnabledFeature('smoothTrackingEnabled')) {
      if (!hasEnabledFeature('geometrisEnabled') && !insuracePortalUserRole) {
        getDriverStatus({
          apiId: 'getDriverStatus',
          data: { isDashboard: true }
        });
      } else {
        getVehicleStatus({ apiId: 'getVehicleStatus' });
      }
      const interval = setInterval(() => {
        if (!hasEnabledFeature('geometrisEnabled') && !insuracePortalUserRole) {
          getDriverStatus({
            apiId: 'refreshDashboardMap',
            data: { isDashboard: true }
          });
        } else {
          getVehicleStatus({ apiId: 'refreshDashboardMap' });
        }
      }, 10000);
      return () => clearInterval(interval);
    }
  }, []);

  const [value, setValue] = useState(0);
  const [sourceToDisplay, setSourceToDisplay] = useState([]);
  const [sourceList, setSourceList] = useState(dataList || []);
  const [notificationEnabled, setNotificationEnabled] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [modal, setModal] = useState(false)
  const getELDStatusIcon = (data) => {
    if (data === 'NOT_STARTED' || data === 'TAMPERED' || data === 'DISCONNECTED') {
      return "eld-inActive";
    } else if (data === 'CONNECTED') {
      return "eld-active";
    } else if (data === '') {
      return "eld-loggedOut"
    } else {
      return ""
    }
  }

  useEffect(() => {
    if (window.$environment?.clutchNewUI || window.$environment?.SERVICE_PROVIDER === 'riskTheory' || window.$environment.SERVICE_PROVIDER == 'azuga') {
      let data = dataList;
      if (!hasEnabledFeature('csaViolationFieldWaitingEnabled')) {
        data = findAndDeleteParentInJson(
          data,
          'id',
          'csaViolationFieldWaitingEnabled'
        );
      }
      if (!hasEnabledFeature('accidentWebEnabled')) {
          data = findAndDeleteParentInJson(data, 'dependency', 'accidentWebEnabled');
      }
      setSourceList(data);
    }
  }, []);


  const driverStatus = () => (
    <Styled.DriverDashboard>
      <div className="dashboardComponents dashboardDriver">
        <div className="headingsWrapper">
          <span className="mainHeading">
            {i18n.t('dashboard.tabs.driverStatus.heading')}
          </span>
          <LinkLi
            component={Link}
            to={pageUrls.driverStatusViewMore}
            className="viewAll"
            onClick={() => history.push(pageUrls.driverStatusViewMore)}
          >
            {i18n.t('View All')}
          </LinkLi>
        </div>
        <DriverStatus
          driverStatus={(hasEnabledFeature('geometrisEnabled') ? dashboard?.data?.driversOnDuty?.infoItems : dashboard?.driverStatusList) || []}
          i18n={i18n}
          columns={hasEnabledFeature('geometrisEnabled') ? undefined : driverStatusTableColumns}
          generateRow={hasEnabledFeature('geometrisEnabled') ? undefined : generateRowDriverStatus}
        />
      </div>
    </Styled.DriverDashboard>
  );
  useEffect(() => {
    localStorage.setItem('daltnot', dashboard?.data?.enableNotification);
  }, [dashboard?.data?.enableNotification]);

  const getIdValue = (event) => {
    setNotificationEnabled(event);
    if (dashboard.data.enableNotification === false) {
      localStorage.setItem('daltnot', event);
      return true;
    } else if (
      dashboard.data.enableNotification === true &&
      localStorage.getItem('daltnot') === 'true'
    ) {
      localStorage.setItem('daltnot', event);
      return false;
    } else {
      localStorage.setItem('daltnot', event);
      return true;
    }
  };
  const driverBehavior = () => (
    <Styled.DriverDashboard>
      <div className="dashboardComponents dashboardDriver driverBehaviour">
        <DriverBehavior i18n={i18n} history={history} callback={callback} />
      </div>
    </Styled.DriverDashboard>

  );
  const alerts = () => (
    <Styled.DriverDashboard>
      <div className="dashboardComponents dashboardDriver">
        <div className="headingsWrapper">
          <span className="mainHeading">
            {i18n.t('dashboard.tabs.alerts.heading')}
          </span>
          <LinkLi
            component={Link}
            to={pageUrls.alertViewMore}
            className="viewAll"
            onClick={() => history.push(pageUrls.alertViewMore)}
          >
            {i18n.t('View All')}
          </LinkLi>
        </div>
        <Alerts
          list={dashboard.data?.alerts?.dashboardAlerts || []}
          handleDeleteAlert={handleDeleteAlert}
          callback={callback}
          i18n={i18n}
        />
      </div>
    </Styled.DriverDashboard>
  );
  const recentAccidents = () => (
    <>
      <Styled.DriverDashboard>
        <div className="dashboardComponents dashboardDriver">
          <div className="headingsWrapper">
            <span className="mainHeading">
              {i18n.t('dashboard.tabs.recentAccidents')}
            </span>
            <LinkLi
              component={Link}
              to={pageUrls.accidentsList}
              className="viewAll"
              onClick={() => history.push(pageUrls.accidentsList)}
            >
              {i18n.t('View All')}
            </LinkLi>
          </div>
          <RecentAccidents
            i18n={i18n}
            recentAccidents={dashboard?.data?.recentAccidents?.infoItems || []}
            viewReport={viewReport}
          />
        </div>
      </Styled.DriverDashboard>
    </>
  );
  const radiusComplianceAlerts = () => (
    <Styled.DriverDashboard>
      <div className="dashboardComponents dashboardDriver">
        <div className="headingsWrapper">
          <span className="mainHeading">
            {i18n.t('dashboard.tabs.alerts.heading')}
          </span>
          <LinkLi
            component={Link}
            to={pageUrls.radiusComplianceAlertsViewMore}
            className="viewAll"
            onClick={() => history.push(pageUrls.radiusComplianceAlertsViewMore)}
          >
            {i18n.t('View All')}
          </LinkLi>
        </div>
        <RadiusComplianceAlerts
          list={dashboard.insuranceData?.alerts || []}
          callback={callback}
          i18n={i18n}
        />
      </div>
    </Styled.DriverDashboard>
  );

  const overview = () => (
    <Styled.DriverDashboard>
      <div className="dashboardComponents dashboardDriver">
        <div className="headingsWrapper">
          <span className="mainHeading">
            {i18n.t('dashboard.tabs.overview')}
          </span>
          <LinkLi
            component={Link}
            to={pageUrls.fleetCompanies}
            className="viewAll"
            onClick={() => history.push(pageUrls.fleetCompanies)}
          >
            {i18n.t('View All')}
          </LinkLi>
        </div>
        <Overview
          list={dashboard.insuranceData?.overviews || []}
          callback={callback}
          i18n={i18n}
        />
      </div>
    </Styled.DriverDashboard>
  );

  const potentialViolations = () => (
    <Styled.DriverDashboard>
      <div className="dashboardComponents dashboardDriver">
        <div className="headingsWrapper">
          <span className="mainHeading">
            {i18n.t('dashboard.tabs.potentialViolations.heading')}
          </span>
          <LinkLi
            component={Link}
            to={pageUrls.potentialViolationsViewMore}
            className="viewAll"
            onClick={() => history.push(pageUrls.potentialViolationsViewMore)}
          >
            {i18n.t('View All')}
          </LinkLi>
        </div>
        <PotentialViolations
          i18n={i18n}
          potentialViolations={dashboard?.data?.driversAtRisk?.infoItems || []}
        />
      </div>
    </Styled.DriverDashboard>
  );

  const csaViolationFieldWaitingEnabled = () => <CsaViolation />;

  useEffect(() => {
    if (window.$environment?.clutchNewUI || window.$environment?.SERVICE_PROVIDER === 'riskTheory' || window.$environment.SERVICE_PROVIDER == 'azuga') {
      setNotificationEnabled(dashboard?.data?.enableNotification);
      const sourceToDisplay = sourceList.map((item) => {
        item.value = eval([item.id] + '()');
        item.name = (
          <>
            {item.icon && <SvgIcon value={item?.icon} />}
            <span>{i18n.t(item.nameKey)}</span>
          </>
        );
        return item;
      });
      if (window.$environment?.SERVICE_PROVIDER === 'riskTheory') {
        let source = []
        if (insuracePortalUserRole) source = sourceToDisplay.filter(ele => ele.id !== 'alerts' && ele.id !== 'driverStatus' && ele.id !== 'potentialViolations' && ele.id !== 'driverBehavior')
        else source = sourceToDisplay.filter(ele => ele.id !== 'overview' && ele.id !== 'radiusComplianceAlerts')
        setSourceToDisplay(source);

      } else {
        setSourceToDisplay(sourceToDisplay);
      }
    }
  }, [
    dashboard.data,
    sourceList,
    dashboard?.data?.dailyLogs?.infoItems,
    dashboard?.data?.driversAtRisk?.infoItems,
    dashboard?.data?.recentAccidents?.infoItems,
    dashboard?.data?.enableNotification,
    notificationEnabled,
    dashboard?.insuranceData?.alerts,
    dashboard?.insuranceData?.overviews
  ]);

  const driverStatusTableColumns = [
    { id: 'name', disablePadding: false, label: i18n.t('Driver Name') },
    {
      id: 'unitName',
      numeric: true,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.unit')
    },
    {
      id: 'driveTimeRemaining',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.driveTime')
    },
    {
      id: 'onDutyTimeRemaining',
      numeric: false,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.onDutyTime')
    },
    {
      id: 'dutyCycleRemaining',
      numeric: true,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.dutyCycleRemaining')
    },
    {
      id: 'lastKnownLocation',
      numeric: false,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.lastKnownLocation')
    },
    {
      id: 'lastUpdated',
      numeric: true,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.lastUpdated')
    },
    {
      id: 'currentStatus',
      disablePadding: false,
      label: i18n.t('Logs')
    },
    ...((window?.$environment?.SERVICE_PROVIDER === 'cyntrx' || window.$environment.SERVICE_PROVIDER === 'nauto') ? [{
      id: 'eld',
      numeric: true,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.eld')
    }] : []),
  ];

  const generateRowDriverStatus = () => {
    const updatedList = dashboard?.driverStatusFilteredList.map(
      (item, index) => {
        const rowItem = { ...item };
        rowItem.currentStatus = item.dailyLogId ? (
          <Icon
            src={getImagePath(imageUrls.logsSelectedIcon)}
            tableicon
            onClick={() =>
              history.push(pageUrls.logsView.replace(':id', item.dailyLogId))
            }
          ></Icon>
        ) : (
          '-'
        );
        rowItem.driveTimeRemaining = `${rowItem.driveTimeRemaining} hours`;
        rowItem.onDutyTimeRemaining = `${rowItem.onDutyTimeRemaining} hours`;
        rowItem.dutyCycleRemaining = `${rowItem.dutyCycleRemaining} hours`;
        if (window?.$environment?.SERVICE_PROVIDER === 'cyntrx' || window.$environment.SERVICE_PROVIDER === 'nauto') {
          rowItem.eld = (
            <a
              onClick={() => {
                if (rowItem.dailyLogUrl)
                  history.push(rowItem.dailyLogUrl.replace('.', '/'));
                else setModal(true);
              }}
            >
              <div className={getELDStatusIcon(rowItem.eldState)} />
            </a>
          );
        }
        return rowItem;
      }
    );
    return updatedList;
  };
  return (
    <Styled.Wrapper>
      {window.$environment?.SERVICE_PROVIDER !== 'cyntrx' && window.$environment?.SERVICE_PROVIDER !== 'webfleet' && window.$environment?.SERVICE_PROVIDER !== 'azuga' && (
        <div className="mapSection">
          <GoogleMap
            type={'dashboardMap'}
            liveTracking={hasEnabledFeature('smoothTrackingEnabled')}
            height={'400px'}
            data={
              hasEnabledFeature('geometrisEnabled') || insuracePortalUserRole
                ? dashboard?.vehicleStatusList
                : dashboard?.driverStatusList
            }
            getDriverStatus={getDriverStatus}
            dashboard={dashboard}
            viewOnMap={null}
          />
        </div>
      )}
      {window?.$environment?.clutchNewUI || window.$environment.SERVICE_PROVIDER == 'azuga' || window?.$environment.SERVICE_PROVIDER === 'riskTheory' ? (
        <Tab
          data={sourceToDisplay}
          currentTab={value}
          onChange={handleChange}
          className="tabs"
        />
      ) : (
        <Styled.DriverDashboard fullWidth={hasEnabledSettings('HIDE_NOTIFICATION')}>
          <div className="dashboardComponents dashboardDriver">
            <div className="headingsWrapper">
              <span className="mainHeading">
                {i18n.t('dashboard.tabs.driverStatus.heading')}
              </span>
              <LinkLi
                component={Link}
                to={pageUrls.driverStatusViewMore}
                className="viewAll"
                onClick={() => history.push(pageUrls.driverStatusViewMore)}
              >
                {i18n.t('View All')}
              </LinkLi>
            </div>
            <DriverStatus
              driverStatus={(hasEnabledFeature('geometrisEnabled') ? dashboard?.data?.driversOnDuty?.infoItems : dashboard?.driverStatusList) || []}
              i18n={i18n}
              columns={hasEnabledFeature('geometrisEnabled') ? undefined : driverStatusTableColumns}
              generateRow={hasEnabledFeature('geometrisEnabled') ? undefined : generateRowDriverStatus}
            />
          </div>
          {hasEnabledSettings('HIDE_NOTIFICATION') ? null : <div className="dashboardComponents dashboardViolation">
            <div className="headingsWrapper">
              <span className="mainHeading">
                Notifications
                {/* {i18n.t('dashboard.tabs.potentialViolations.heading')} */}
              </span>
              <LinkLi
                component={Link}
                to={pageUrls.potentialViolationsViewMore}
                className="viewAll"
                onClick={() =>
                  history.push(pageUrls.potentialViolationsViewMore)
                }
              >
                {i18n.t('View All')}
              </LinkLi>
            </div>
            <Notifications
              potentialViolations={
                dashboard?.data?.driversAtRisk?.infoItems || []
              }
              i18n={i18n}
            />
          </div>}
        </Styled.DriverDashboard>
      )}
      {window.$environment.SERVICE_PROVIDER === 'at' || window.$environment.SERVICE_PROVIDER === 'simplex' &&
        (<Styled.DriverDashboard>
          <div className="dashboardComponents dashboardDriver dashboardDriverBehaviour">
            <div className="headingsWrapper">
              <span className="mainHeading">
                {i18n.t('dashboard.tabs.driverBehavior')}
              </span>
            </div>
            <DriverBehavior i18n={i18n} history={history} callback={callback} />

          </div>
        </Styled.DriverDashboard>)
      }
      <Modal
        open={modal}
        setOpen={() => setModal(m => !m)}
        withCloseIcon
        alignItemsLeft
        noDataPopup
        data={{
          header: '',
          content: () => <div >No Logs available</div>
        }}
      />
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  history: PropTypes.object.isRequired,
  potentialViolations: PropTypes.array.isRequired
};
