import PropTypes from 'prop-types';
import React, { cloneElement, lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import { removeLocalStorage } from '@/services/storage';
import {
  COOKIE_AUTH_DETAILS,
  imageUrls,
  pageUrls
} from '../../../config/constants/keys';
import { deleteCookie, getCookie } from '../../../services/cookie';
import { checkIsEmbededPage, getAuthDetails, getEsKey, getGenerateModuleRelation, getImagePath } from '../../../utils/utils';
import Styled from './privateStyle';

const layout = window?.$environment?.CURRENT_LAYOUT;
const ErrorIcon = lazy(() => import(`../../ErrorIcon/${layout}`));
const SuccessIcon = lazy(() => import(`../../SuccessIcon/${layout}`));
const Button = lazy(() => import(`../../UI/Button/${layout}`));
const Header = lazy(() => import(`../../Header/${layout}`));
const Footer = lazy(() => import(`../../Footer/${layout}`));
const LeftMenu = lazy(() => import(`../../LeftMenu/${layout}`));
const Logo = lazy(() => import(`../../Logo`));
const Loader = require(`../../Loader`);
const Modal = require(`../../UI/Modal/${layout}`).default;
const PrivateWrapper = ({
  children,
  layout,
  apiStatus,
  clearRedux,
  setAuth,
  showFilterPopup,
  folderPath,
  handleLogout,
  getCameraUrl,
  cameraUrl,
  fetchCameraUrl
}) => {
  const theme = useTheme();
  // const [open, setOpen] = useState(true);
  const [leftMenuList, setLeftMenuList] = useState([]);
  const history = useHistory();
  const [loaderPopupOpen, setLoaderPopupOpen] = React.useState(false);
  const [openCallback, setOpenCallback] = React.useState(false);
  const i18n = useTranslation();
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => (
      <Button
        type="submit"
        label={i18n.t('common.ok')}
        onClick={() => setOpenCallback(false)}
      />
    )
  });

  const [isError, setIsError] = React.useState(false);
  const handleOnLogout = () => {
    const auth = getAuthDetails(false);
    if (auth) {
      handleLogout({
        apiId: 'logout',
        callback: (type, message) => {
          if (type === 'error') {
            callback(type, message);
          } else {
            removeLocalStorage('currentLayout');
            // window.location.reload();
            window.location.href = pageUrls.login;
          }
        }
      });
    }
  };


  // useEffect(() => {
  //   const auth = getAuthDetails(false);
  //   const token = getCookie(COOKIE_AUTH_DETAILS);
  //   if(auth && window.$environment.SERVICE_PROVIDER === "gs" && !fetchCameraUrl && token && token.enterpriseFeatureDto && token.enterpriseFeatureDto.cameraIntegrationEnabled){
  //       if(token.roles && ( token.roles.includes("FLMANAGER") || token.roles.includes("ADMIN") )){
  //         getCameraUrl();
  //       }
  //   }
  // }, []);


  //Callback function to be invoked after an api call
  const callback = (type, msg, callbackFunction, errors, optionButton, isListErr = false) => {
    let message = msg
    if (Array.isArray(msg) && msg.length == 1) message = msg[0]
    if (message) {
      let keys = Object.keys(message);
      let items = Object.values(message);
      if (typeof message !== 'string' && keys.length > 1) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      setModalListObject({
        header: '',
        content: () => (
          <div className="successpopup">
            {type === 'error' ? (
              <ErrorIcon />
            ) : type === 'warning' || type === 'warningfmcsa' ? (
              <img
                src={getImagePath(imageUrls.confirmIcon)}
                alt="Are you sure"
              />
            ) : (
              <SuccessIcon />
            )}
            {type === 'warning' ? (
              <span className="warningPopup">Warning</span>
            ) : (
              ''
            )}
            {typeof message === 'string' ? (
              type === 'warning' ? (
                <><span style={errors.length > 0 ? { paddingBottom: '22px' } : {}} className="warningPopupMessage">{i18n.t(message)}</span>
                  {errors.length > 0 && <div style={{ paddingBottom: '22px' }}>{errors.map(ele => <div style={{ paddingBottom: '0' }} className="warningPopupMessage">{ele}</div>)}</div>}</>
              ) : (
                <h2> {i18n.t(getEsKey(message))} </h2>
              )
            ) : keys.length === 1 && items[0].length === 1 && isListErr ? (
              <div className="errorSectionlist">
                <div className="tcSection" id="scroll">
                  <div className="scrollbar">
                    {keys.map((item, index) => {
                      return (
                        <div className="listItem">
                          <span>Line No:{item}</span>
                          <ul>
                            {message[item].map((err, index) => {
                              return <li>{err}</li>;
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : keys.length === 1 && items[0].length === 1 ? (
              <h2> {i18n.t(items)} </h2>
            ) : keys.length === 1 && items[0].length !== 1 ? (
              <div className="errorSectionlist">
                <div className="tcSection" id="scroll">
                  <div className="scrollbar">
                    {keys.map((item, index) => {
                      return (
                        <div className="listItem">
                          <span>Line No:{item}</span>
                          <ul>
                            {message[item].map((err, index) => {
                              return <li>{err}</li>;
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : type !== 'error' && message?.error?.length === 0 ? (
              <h2> {i18n.t('common.dataImportSuccess')} </h2>
            ) : (
              <div className="errorSectionlist">
                <div className="tcSection" id="scroll">
                  <div className="scrollbar">
                    {keys.map((item, index) => {
                      return (
                        <div className="listItem">
                          <span>Line No:{item}</span>
                          <ul>
                            {message[item].map((err, index) => {
                              return <li>{err}</li>;
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {
              optionButton ? <div className="deletePopupBtn">
              <div className="cancelBtn">
                <Button
                  label={i18n.t('Cancel')}
                  type="submit"
                  onClick={() => {
                    setOpenCallback(false);
                  }}
                ></Button>
              </div>
              <div className="deletebtn">
                <Button
                  type="submit"
                  label={i18n.t('common.deletePopup')}
                  onClick={() => {
                      callbackFunction()
                    }}
                  ></Button>
                </div>
              </div> 
              : 
              <div className="deletePopupBtn">
              <Button
                type="submit"
                label={i18n.t('common.ok')}
                onClick={() => {
                  setOpenCallback(false);
                  callbackFunction && callbackFunction();
                  if (
                    type !== 'error' &&
                    message?.type === 'Users' &&
                    window.location.href.includes('user')
                  )
                    history.push(pageUrls.userList);
                  else if (
                    type !== 'error' &&
                    (message?.type === 'Vehicles' ||
                      message?.type === 'Trailers') &&
                    window.location.href.includes('vehicle')
                  )
                    history.push(pageUrls.vehicleList);
                  else if (
                    type !== 'error' &&
                    window.location.href.includes('alerts') &&
                    window.location.href.includes('requests')
                  )
                    history.push(pageUrls.alertsRequests);
                  else if (
                    type === 'mailIdChanged' &&
                    window.location.href.includes('user') &&
                    window.location.href.includes('profile') &&
                    message === 'Your profile was saved'
                  ) {
                    handleOnLogout();
                  } else if (
                    type !== 'error' &&
                    window.location.href.includes('user') &&
                    window.location.href.includes('resetPassword') &&
                    message === 'Your profile was saved'
                  ) {
                    // handleOnLogout();
                    window.location.href = pageUrls.login;
                    // history.push(pageUrls.login);
                  } else if (
                    type !== 'error' &&
                    // window.location.href.includes('dashboard') &&
                    (message ===
                      'A verification email has been sent to your email. Please click on the link to enable email notifications' ||
                      message === 'You have disabled email notifications')
                  ) {
                    let btn = document.getElementById(
                      'checkbox_Enable Notification'
                    );
                    if (btn && btn.parentNode.classList.contains('checked')) {
                      btn && btn.parentNode.classList.remove('checked');
                      btn && btn.parentNode.classList.add('unchecked');
                    }
                  }
                }}
              />
            </div>
            }
          
          </div>
        )
      });
      setOpenCallback(true);
    }
  };
  useEffect(() => {
    let isPageLoading = false;
    for (let index = 0; index < apiStatus.status.length; index++) {
      const element = apiStatus.status[index];
      if (element.isLoading) {
        isPageLoading = true;
      }
      if (element.errorMessage === 'Access denied') {
        setLoaderPopupOpen(false);

        deleteCookie(COOKIE_AUTH_DETAILS);
        clearRedux();
        setAuth(null);
        removeLocalStorage('currentLayout');
        window.location = pageUrls.login;
        return;
      }
    }
    isPageLoading ? setLoaderPopupOpen(true) : setLoaderPopupOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus.status]);

  useEffect(() => {
    const token = getCookie(COOKIE_AUTH_DETAILS);
    const basePath = `config/menu${folderPath}${window.$environment.SERVICE_PROVIDER}/${window.$environment.CURRENT_LAYOUT}`;
    const path =
      token?.previousPaymentFailed || token?.adminSuspended
        ? require(`@/${basePath}/resetPassword`)
        : layout === 'authenticatedStyle'
          ? require(`@/${basePath}/resetPassword`)
          : require(`@/${basePath}/leftMenu`);

        if( window.$environment.SERVICE_PROVIDER === "gs" && token && token.enterpriseFeatureDto){
          let payload = [];
          if(token.enterpriseFeatureDto.cameraIntegrationEnabled && !token?.adminSuspended){
              payload.push({
              icon: 'camera.svg',
              label: 'leftMenu.camera',
              roles: ['FLMANAGER', 'ADMIN'],
              path: '/camera',
              // link: cameraUrl,
              // openNewTab: true
            })
          }
          if(token.enterpriseFeatureDto.fleetOpsEnabled && !token?.adminSuspended){
              payload.push({
              icon: 'freight.svg',
              label: 'leftMenu.freight',
              roles: ['FLMANAGER', 'ADMIN'],
              path: '/fleetops',
              link: cameraUrl,
              openNewTab: true
            })
          }
          setLeftMenuList(getGenerateModuleRelation([...path.default, ...payload]));
        }else{
          setLeftMenuList(getGenerateModuleRelation(path.default));
        }
    if (token?.previousPaymentFailed) {
      if (
        !window.location.href.includes('feedback') &&
        !window.location.href.includes('profile')
      )
        history.push({
          pathname: pageUrls.suspended,
          state: {
            contractFinishDate: token?.contractFinishDate,
            contractStartDate: token?.contractStartDate,
            amount: token?.amountToBePaid
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderPath]);

  const bigDrawerWidth = '15.1%';
  const bigDrawerWidthNoUnit = '15.1';
  const smDrawerWidth = '6.1%';
  const smDrawerWidthNoUnit = '6.1';

  const sm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const lg = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  const mdWidth = md ? smDrawerWidth : bigDrawerWidth;
  let width = sm ? bigDrawerWidth : mdWidth;
  const mdWidthNoUnit = md ? smDrawerWidthNoUnit : bigDrawerWidthNoUnit;
  let widthNoUnit = sm ? bigDrawerWidthNoUnit : mdWidthNoUnit;

  const openLogo = imageUrls.logo;
  const logo = sm || md || lg ? imageUrls.smallLogo : openLogo;

  const image = getImagePath(imageUrls.bg_image);
  const isRestrictedMode = window.location.pathname === pageUrls.resetPassword;
  const isEmbeded = checkIsEmbededPage();


  return (
    <Styled.ParentWrapper>
      <div id="udNotification" style={{ display: 'none' }}></div>
      <Styled.Wrapper>
        {isEmbeded ? <></> : layout !== 'ApologiesPage' &&
          <Styled.LeftDrawer drawerwidth={width} widthnounit={widthNoUnit}>
            <Collapse in={true} collapsedHeight="100%">
              <Box>
                <Box id="logoContainer">
                  <Logo
                    history={history}
                    src={getImagePath(logo)}
                  // width={!open ? '80px' : ''}
                  />
                </Box>

                {layout !== 'authenticatedWithoutMenu' && (
                  <LeftMenu
                    needToolTip={false}
                    showFilterPopup={showFilterPopup}
                    leftMenuList={leftMenuList}
                    noAuthCheck={layout === 'authenticatedStyle'}
                    cameraUrl={cameraUrl}
                  />
                )}
              </Box>
            </Collapse>
          </Styled.LeftDrawer>
        }

        <Styled.Main
          widthnounit={isEmbeded ? 0 : layout === 'ApologiesPage' ? 0 : widthNoUnit}
          drawerwidth={width}
          image={image}
        >
          {layout !== 'authenticatedWithoutMenu' && layout !== 'ApologiesPage' && (
            <Header
              history={history}
              handleLogout={handleLogout}
              callback={callback}
              isRestrictedMode={isRestrictedMode}
            />
          )}
          <Styled.Content>
            {cloneElement(children, { callback })}
          </Styled.Content>
        </Styled.Main>
        {isEmbeded ? <></> : <Styled.FooterWrapper drawerwidth={width}>
          <Footer />
        </Styled.FooterWrapper>}
        <Modal
          open={loaderPopupOpen}
          setOpen={setLoaderPopupOpen}
          data={{
            header: '',
            content: () => <Loader.default />
          }}
          isLoader
        />
        <Modal
          open={openCallback}
          setOpen={setOpenCallback}
          data={ModalListObject}
          isSuccess
          isError={isError}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
        />
      </Styled.Wrapper>
    </Styled.ParentWrapper>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    cameraUrl: state.cameraUrl.url,
    fetchCameraUrl: state.cameraUrl.fetchCameraUrl
  };
};
const mapDispatch = (dispatch) => {
  return {
    clearRedux: () => dispatch({ type: 'RESET_APP' }),
    handleLogout: (data) => dispatch.login.handleLogout(data),
    getCameraUrl: () => dispatch.cameraUrl.getCameraUrl()
  };
};

export default connect(mapState, mapDispatch)(PrivateWrapper);

PrivateWrapper.defaultProps = {
  children: null,
  layout: ''
};
PrivateWrapper.propTypes = {
  children: PropTypes.element,
  layout: PropTypes.string,
  clearRedux: PropTypes.func.isRequired,
  setAuth: PropTypes.func.isRequired
};
