import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { getStyle } from '../../../utils/utils';

const Header = styled(Box)`
  ${({ theme }) => `
  display: flex;
  padding-bottom: 30px;
  // padding: 3%;
  position: relative;
  justify-content: space-between;
  .filterForm {
    flex: 0 0 90%;
    .MuiCheckbox-root {
      padding: 7px !important;
    }
    div[type='text'],
    div[type='date'], div[type='select'] {
      width: 18%;
      flex-direction: column;
      p {
        color: ${theme.colors.PRIMARY_CONTRAST};
      }
      @media (max-width: ${theme.breakpoints.values.md}px) {
        width: auto;
        margin-bottom: 16px;
      }
      @media only screen and (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${theme.breakpoints.values.mlg}px) {
        margin-right: 18px;
      }
    }
    div[type='submit'],
    div[type='reset'] {
      align-items: flex-end;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        margin-bottom: 16px;
      }
    }
    .MuiInput-root {
      @media (max-width: ${theme.breakpoints.values.md}px) {
        padding: 12px 10px;
      }
    }
    .MuiInput-root,
    .react-datepicker-wrapper {
      @media (max-width: ${theme.breakpoints.values.md}px) {
        min-width: 180px;
      }
    }
  }
  .exportForm {
    flex-wrap: nowrap !important;
    padding-left: 10px;
    padding-bottom: 10px;
  }

  .MuiGrid-root {
    justify-content: flex-end;
  }
  .exportContainer {
    display: flex;
    align-items: flex-end;
    flex: 0 0 10%;
    .MuiAvatar-root {
      width: 35px !important;
      height: 35px !important;
    }
  }
  .tooltiptext {
    width: 155px;
    font-size: 15px;
    right: 0;
    top: 82px;
  }
  .MuiAvatar-root {
    width: 35px;
    height: 35px;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
  }
  `}
`;
const ToolsContainer = styled(Box)`
  ${({ theme, selectedindex }) => `
  position: absolute;
  background: ${
    (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
  };
  border: solid 2px
    ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
  top: ${selectedindex ? selectedindex + 'px' : '280px'};
  right: 0;
  li {
    list-style: none;
  }
  a {
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    font-size: 20px;
  }
  .ToolsOptionClose {
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    position: absolute;
    margin-top: 10px;
    margin-right: 8px;
  }

  ul {
    margin-top: 20px;
    width: 175px;
    height: auto;
    padding-inline-start: 25px;
  }
  cursor: pointer;

  `}
`;

const tableWrapper = styled(Box)`
.spireonOldOnly,
.spireonNewOnly{
  display: none;
}
// padding: 0 3%;
position: relative;
  // .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(7) {
  //   width: 30px;
  // }
  // .iconContainer .MuiAvatar-root {
  //   width: 18px !important;
  //   height: auto !important;
  // }
  // .iconContainer .MuiAvatar-rounded {
  //   border-radius: 0px !important;
  // }
`;

const ExportWrapper = styled(Box)`
  border: solid 2px
    ${(({ style, theme }) =>
      getStyle((style || {}).border, theme.palette.primary || {}).border,
    '')};
  background-color: ${(({ style, theme }) =>
    getStyle((style || {}).border, theme.palette.primary || {}).main,
  '')};
  width: 450px;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  transition-property: left;
  transition-duration: 2s;
  right: ${({ right }) => right || '0px'};
`;
const Content = styled(Box)`
  .goBack {
    position: absolute;
    top: 8px;
    left: 2%;
    z-index: 99999;
      svg {
      color: #ccc !important;
    }
  }
  .logwrapper {
    .MuiTabs-root {
      padding-left: 3%;
    }
  }
`;

const Styled = { Header, ToolsContainer, tableWrapper, ExportWrapper, Content };

export default Styled;
