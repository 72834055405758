import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { getStyle } from '../../../utils/utils';
import Parent from './style';

const ExportWrapper = styled(Box)`
  border: solid 2px
    ${(({ style, theme }) =>
      getStyle((style || {}).border, theme.palette.primary || {}).border,
    '')};
  background-color: ${(({ style, theme }) =>
    getStyle((style || {}).border, theme.palette.primary || {}).main,
  '')};
  width: 450px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  transition-property: left;
  transition-duration: 2s;
  right: ${({ right }) => right || '0px'};
`;

const Header = styled(Parent.Header)`
  ${({ theme }) => `
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 5px;
.filterForm {
  padding: 0 25px;
  padding-top: 10px;
  width: 100%;
    .MuiBox-root[type="text"] p {
        display: none;
    }
    p {
        color: #000000 !important;
        font-size: 20px !important;
        opacity: 0.87;
    }
    div[type='date'] {
      position: relative;
      width: 10%;
      .MuiSvgIcon-root {
        position: absolute;
        right: 0;
        width: 18px;
        height: 18px;
        color: #767676;
        bottom: 7px;
      }
    }
}
.datepopup {
  margin-left: 25px;
  margin-top: 10px;
  border: 0;
  width: auto;
  .MuiButton-root {
    padding: 0 25px !important;
  }
  .vertical {
    flex: 0 0 35%;
    .MuiFormLabel-root{
      font-size: 20px;
    }
    .react-datepicker-wrapper {
      padding-left: 0 !important;
    }
  }
}
.exportContainer {
  padding-left: 25px;
  padding-top: 16px;
  a {
    font-size: 15px;
    color: #007aff;
    text-decoration: none;
  }
  svg {
    top: 5px;
    position: relative;
  }
  .tooltiptext {
    display: none;
  }
}
`}
`;
const ToolsContainer = styled(Parent.ToolsContainer)`
  ${({ theme }) => `
  border: 1px solid #ddd;
  background: ${theme.colors.WHITE};
  right: 0%;
  padding-bottom: 10px;
  a {
    font-weight: bold;
    font-size: 14px;
  }
`}
`;
const tableWrapper = styled(Parent.tableWrapper)`
  ${({ theme }) => `
  .spireonNewOnly{
    display flex;
    align-items: flex-end;
    padding-left: 25px;
    padding-bottom: 20px;
    span {
        padding-right: 25px;
        font-size: 20px;
    }
  }
  .MuiTable-root .MuiTableRow-root.nodataspireon .MuiTableCell-root:last-child {
    text-align: center !important;
  }
  .logbookIcon {
    width: 14px;
    height: 20px;
    margin-left: -28px;
    opacity: 0.5;
    transform: rotate(8deg);
  }
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(3) {
    width: 32px;
    text-align: right !important;
    .historyIcon {
      width: 20px;
      height: 20px;
      color: #757575;
    }
  }
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(2) {
    width: 26px;
    padding-left: 11px !important;
    text-align: left !important;
    .historyIcon {
      width: 20px;
      height: 20px;
      color: #757575;
      margin-left: -24px;
    }
  }
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:last-child {
    padding-left: 0 !important;
    text-align: left !important;
    .historyIcon {
      width: 20px;
      height: 20px;
      color: #757575;
      margin-left: -29px;
    }
  }
.spireonOldOnly {
  display: none;
}
.totalSeperatorsp {
  padding-right: 2%;
padding-top:0;
.seperator {
  justify-content: flex-end;
  flex-direction: column;
  p:first-child {
    color: #000;
    font-size: 20px;
    text-align: right;
  }
  :first-child {
    padding-top: 0;
  }
}
.seperator:first-child {
  padding-right: 27px;
 }
p:last-child {
  font-size: 18px;
}
}
padding: 0;
`}
`;
const Content = styled(Parent.Content)`
position: relative;
.goBack {
  position: absolute;
  top: 11px;
  left: 25px;
    svg {
      color: #ccc !important;
    }
}
header {
  box-shadow: none;
  padding-left: 25px;
}
`;

const Styled = { Header, ToolsContainer, tableWrapper, Content };

export default Styled;
