import PropTypes from 'prop-types';
import React, { lazy, useContext, useEffect, useState } from 'react';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
// import { deleteCookie } from '@/services/cookie';

import { getCookie } from '@/services/cookie';
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage
} from '@/services/storage';
import { COOKIE_AUTH_DETAILS, imageUrls, pageUrls } from '../../../config/constants/keys';
import AuthContext from '../../../services/auth';
import {
  checkIsEmbededPage,
  findListItem,
  getAuthDetails,
  getImagePath,
  hasEnabledFeature,
  hasRestrictedRole,
  setLanguage,
  setLanguageFromStorage,
  showLanguageCode
} from '../../../utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Chat = lazy(() => import(`../../Chat/${layout}`));
const SvgIcon = lazy(() => import(`../../SvgIcon/${layout}`));
const Icon = lazy(() => import(`../../UI/Icon/${layout}`));
const Link = lazy(() => import(`../../UI/Link`));
const Menu = lazy(() => import(`../../LeftMenu/${layout}`));

const Header = ({
  history,
  handleLogout,
  callback,
  isRestrictedMode,
  Styled,
  keepi18nTextAsSame,
  headerMenu,
  menuList,
  showFilterPopup
}) => {
  const { i18n } = useTranslation();
  const [hamburgerList, setHamburgerList] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);
  const [chatPopupOpen, setChatPopupOpen] = useState(false);
  const theme = useTheme();

  const profile = getImagePath(imageUrls.profile);
  const isBiggerThanTab = !useMediaQuery(theme.breakpoints.down('sm'));

  const auth = useContext(AuthContext);
  const token = getCookie(COOKIE_AUTH_DETAILS);
  const [profileIcon, setProfileIcon] = useState('')
  useEffect(() => {
    if (token) {
      if (token.firstName) {
        let firstName = token.firstName.replace(/[^a-zA-Z0-9 ]/g, "").trim()
        let lastName = token.lastName.replace(/[^a-zA-Z0-9 ]/g, "").trim()
        if (lastName) {
          if (firstName) setProfileIcon(`${firstName[0]}${lastName[0]}`)
          else setProfileIcon(`${lastName[0]}`)
        } else if (firstName) {
          setProfileIcon(`${firstName[0]}`)
        }
      }
    }
  }, [token]);

  useEffect(() => {
    // Change Code Here
    const code = setLanguageFromStorage(i18n, 'en');
    if (document.getElementById(code)) {
      document.getElementById(code).selected = true;
    }
    document.addEventListener('click', (event) => {
      if (
        event?.target?.parentElement?.parentElement?.parentElement?.id ===
        'menu'
      ) {
      } else if (
        event?.target?.parentElement?.parentElement?.id !== 'profileIcon' && event?.target?.id !== 'profileIconNew'
      ) {
        setOpenMenu(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let options =
      menuList?.hamburgerMenu?.length &&
        window.$environment.SERVICE_PROVIDER === 'gs'
        ? [
          ...menuList.hamburgerMenu,
          {
            // icon: imageUrls.oldView,
            label: i18n.t('avatarMenu.switchToNew'),
            handleOnClick: () => {
              toggleLayout();
            },
            roles: []
          },
          {
            label: i18n.t('avatarMenu.logout'),
            handleOnClick: handleOnLogout,
            roles: []
          }
        ]
        : menuList?.hamburgerMenu?.length &&
          window.$environment.SERVICE_PROVIDER !== 'gs'
          ? [
            ...menuList.hamburgerMenu,
            {
              label: i18n.t('avatarMenu.logout'),
              handleOnClick: handleOnLogout,
              roles: []
            }
          ]
          : hasEnabledFeature('chatEnabled') &&
            !hasRestrictedRole(['DRIVER', 'MECHANIC'])
            ? [
              {
                icon: imageUrls.account,
                label: i18n.t('common.account'),
                handleOnClick: () => history.push(pageUrls.profile),
                roles: []
              },

              ...(!isSuspendedScreen()
                ? [
                  {
                    icon: imageUrls.oldView,
                    label: i18n.t('avatarMenu.switchToOld'),
                    handleOnClick: () => {
                      toggleLayout();
                    },
                    roles: []
                  },
                  {
                    icon:
                      getLocalStorage('Language') === 'en'
                        ? imageUrls.language
                        : imageUrls.languageEn,
                    label: 'currentLanguage',
                    handleOnClick: handleOnLanguage,
                    roles: []
                  }
                ]
                : []),
              {
                icon: imageUrls.messaging,
                label: i18n.t('avatarMenu.messaging'),
                handleOnClick: openChat,
                roles: []
              },
              {
                icon: imageUrls.logout,
                label: i18n.t('avatarMenu.logout'),
                handleOnClick: handleOnLogout,
                roles: []
              }
            ]
            : [
              {
                icon: imageUrls.account,
                label: i18n.t('common.account'),
                handleOnClick: () => history.push(pageUrls.profile),
                roles: []
              },

              ...(!isSuspendedScreen()
                ? [
                  {
                    icon: imageUrls.oldView,
                    label: i18n.t('avatarMenu.switchToOld'),
                    handleOnClick: () => {
                      toggleLayout();
                    },
                    roles: []
                  },
                  {
                    icon:
                      getLocalStorage('Language') === 'en'
                        ? imageUrls.language
                        : imageUrls.languageEn,
                    label: 'currentLanguage',
                    handleOnClick: handleOnLanguage,
                    roles: []
                  }
                ]
                : []),

              {
                icon: imageUrls.logout,
                label: i18n.t('avatarMenu.logout'),
                handleOnClick: handleOnLogout,
                roles: []
              }
            ];
    const menu =
      !isBiggerThanTab &&
        menuList?.horizontalMenu &&
        menuList?.horizontalMenu.length
        ? [...menuList.horizontalMenu, ...options]
        : [...options];
    setHamburgerList(menu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBiggerThanTab, getLocalStorage('Language'), i18n]);

  const handleOnLanguage = () => {
    setLanguage(i18n);
    setOpenMenu(false);
  };

  const openChat = () => {
    setOpenMenu(false);
    setChatPopupOpen(true);
  };

  const handleOnLogout = () => {
    const auth = getAuthDetails(false);
    if (auth) {
      // if(window.$environment.SERVICE_PROVIDER === 'tp'){
      //   window.zE('webWidget:on', 'close', function() {
      //     window.zE('webWidget', 'reset');
      //      })
      //     window.$zopim.livechat.clearAll();
      //   window.zE('webWidget', 'close');
      // }
      // setTimeout(() => {
      handleLogout({
        apiId: 'logout',
        callback: (type, message) => {
          // if(window.$environment.SERVICE_PROVIDER === 'tp'){
          //  localStorage.removeItem('ZD-settings')
          //  localStorage.removeItem('ZD-suid')
          //  localStorage.removeItem('ZD-build')
          //  localStorage.removeItem('ZD-store')
          //  localStorage.removeItem('__zlcstore')
          //  window.zE('webWidget:on', 'close', function() {
          // window.zE('webWidget', 'reset');
          //  })
          // window.$zopim.livechat.clearAll();
          // window.zE('webWidget', 'close');
          // window.zE('webWidget', 'reset');
          // window.zE('webWidget', 'chat:end');
          // window.zE('webWidget', 'logout');
          //   window.$zopim.livechat.clearAll();
          // }
          if (type === 'error') {
            callback(type, message);
          } else {
            removeLocalStorage('currentLayout');
            // window.location.reload();
            window.location.href = pageUrls.login;
          }
        }
      });
      // }, 500);

    } else {
    }
  };

  const isSuspendedScreen = () => {
    let token = getCookie(COOKIE_AUTH_DETAILS);
    if (token?.previousPaymentFailed || token?.adminSuspended) return true;
    else return false;
  };

  const NotAuthorizedOptions = [
    {
      icon: imageUrls.oldView,
      label: window.$environment.CURRENT_LAYOUT === 'OldUi' ? i18n.t('avatarMenu.switchToNew') : i18n.t('avatarMenu.switchToOld'),
      handleOnClick: () => {
        toggleLayout();
      }
    }
  ];

  const toggleLayout = () => {
    const currentLayout =
      window.$environment.CURRENT_LAYOUT === window.$environment.APP_LAYOUT
        ? window.$environment.SWITCH_APP_LAYOUT
        : window.$environment.APP_LAYOUT;

    setLocalStorage('currentLayout', currentLayout);
    window.location.reload();
  };

  const generateMenuItem = (value, index) => {
    return (
      <MenuItem
        onClick={value.handleOnClick}
        key={`${value.label}Label${index}`}
      >
        {value.icon ? (
          <ListItemIcon className="menuItem">
            {value.icon.indexOf('.svg') > -1 ? (
              <SvgIcon value={value.icon} />
            ) : (
              <img
                alt={'optionIcon' + value.label}
                src={getImagePath(value.icon)}
              />
            )}

            <span>
              {value.label === 'currentLanguage'
                ? i18n.t(`common.${showLanguageCode('en', keepi18nTextAsSame)}`)
                : value.label}
            </span>
          </ListItemIcon>
        ) : (
          <Link
            href={value.path || ''}
            onClick={(e) => {
              if (value.isFilter) {
                showFilterPopup();
                return false;
              } else if (value.handleOnClick) {
                value.handleOnClick();
                return false;
              } else if (value?.openInNewTab) {
                var win = window.open(`${window.$environment?.FLEETOPS_URL}?token=${token?.token}&partnerId=${window.$environment?.DOMAIN}`, '_blank');
                win.focus();
                e.preventDefault();
                return false;
              }
            }}
          >
            {i18n.t(value.label)}
          </Link>
        )}
      </MenuItem>
    );
  };

  const isEmbeded =  checkIsEmbededPage();


  return (
    <Styled.HeaderContainer>
      {headerMenu && isBiggerThanTab && (
        <Menu
          horizontal
          needToolTip={false}
          leftMenuList={menuList.horizontalMenu}
          noAuthCheck={false}
          showFilterPopup={showFilterPopup}
        />
      )}
      {keepi18nTextAsSame && !isSuspendedScreen() && !window.location.href.includes('resetPassword') && (
        <select name="languages" id="languages" onChange={handleOnLanguage}>
          <option id="en" value="EN">
            EN
          </option>
          <option id="es" value="SP">
            ES
          </option>
        </select>
      )}
      <div id="container">
        {isEmbeded ? <></> : <Styled.IconContainer>
          <div onClick={() => {
            setOpenMenu(!openMenu);
            setChatPopupOpen(false);
          }}
            id="profileIconNew"
            className="profileIconLogo">{profileIcon}</div>
          {/* <Icon
              src={profile}
              onClick={(e) => {
                setOpenMenu(true);
                setChatPopupOpen(false);
                openMenu && setOpenMenu(false);
              }}
              id="profileIcon"
            /> */}


          {openMenu && (
            <Styled.OptionContainer id="menu">
              {isRestrictedMode
                ? NotAuthorizedOptions.map((item, index) =>
                  generateMenuItem(item, index)
                )
                : hamburgerList.map((item, index) => {
                  const isAuthorized = findListItem(
                    item.roles,
                    auth.auth,
                    [item.module],
                    auth.enterpriseFeatureDto,
                    item
                  );
                  if (isAuthorized) {
                    return generateMenuItem(item, index);
                  } else {
                    return null;
                  }
                })}
            </Styled.OptionContainer>
          )}
        </Styled.IconContainer>}
      </div>
      <Styled.ModalContainer>
        <Chat open={chatPopupOpen} setOpen={setChatPopupOpen} />
      </Styled.ModalContainer>
    </Styled.HeaderContainer>
  );
};

export default Header;
Header.defaultProps = {
  header: '',
  handleLogout: () => { },
  callback: () => { },
  keepi18nTextAsSame: false,
  headerMenu: false,
  menuList: {}
};
Header.propTypes = {
  header: PropTypes.string,
  history: PropTypes.object.isRequired,
  handleLogout: PropTypes.func,
  callback: PropTypes.func,
  keepi18nTextAsSame: PropTypes.bool,
  menuList: PropTypes.object,
  headerMenu: PropTypes.bool
};
