import { checkIsEmbededPage } from '@/utils/utils';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  ${({ theme, withBackground, withBackgroundPolicy }) => `
  h1 {
    text-transform: uppercase;
  }
  #container {
    flex-direction: column;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    padding: 4px 1%;
    padding-left: 0;
    ${checkIsEmbededPage() ? `
        flex-direction: row;
        color: ${theme.palette.primary.contrastText};
        justify-content: center;
        align-items: center;
        padding-left: 2%;
    ` : ``}
    padding-bottom: 0;
    background-color: ${theme.colors.PRIMARY_MAIN};
    font-size: 24px;
    h1 {
      padding-left: 3%;
      line-height: 41px;
      flex: 1;
    }
        .hideGoBack {
    display: none;
  }
  .hideGoBack + .searchWrapper h1 {
    padding-left: 0;
    padding-bottom: 27px;
  }
    .embed {
      padding-left: 4% !important;
    }
    .goBackEmbeded {
      position: absolute;
      top: ${(withBackground || withBackgroundPolicy) ? '13px': '1.5%'};
      left: 2%;
      cursor: pointer;
      svg path {
        fill: ${(withBackground || withBackgroundPolicy) ? '#fff': '#ccc'};
      }
    }
  }
  .not-authenticated {
    flex-direction: row !important;
    height: 73px;
    background-color: ${theme.colors.BLACK};
  }
  `}
`;

const Styled = {
  HeaderContainer
};
export default Styled;
