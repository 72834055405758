import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { getStyle } from '../../../utils/utils';

const ExportWrapper = styled(Box)`
  ${({ theme, style }) => `

  border: solid 2px
    ${(getStyle((style || {}).border, theme.palette.primary || {}).border,
      theme.colors.PRIMARY_BORDER)
    };
  background-color: ${(getStyle((style || {}).border, theme.palette.primary || {}).main,
      theme.colors.PRIMARY_MAIN)
    };
  width: 450px;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  transition-property: left;
  transition-duration: 2s;
  right: ${({ right }) => right || '0px'};
  `}
`;
const Header = styled(Box)`
  ${({ theme }) => `

  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  padding: 0 2%;
  align-items: flex-end;
  .filterForm {
    flex-wrap: wrap;
    width: 100%;
    padding-top: 3%;
    div[type='text'],
    div[type='date'] {
      width: 17%;
      margin-right: 1%;
      @media (max-width: 1400px) {
        width: 20%;
      }
      @media (max-width: ${theme.breakpoints.values.md}px) {
        width: auto;
        margin-bottom: 16px;
      }
    }
    div[type='submit'] {
      margin-right: 1%;
    }
    div[type='submit'],
    div[type='reset'] {
      @media (max-width: ${theme.breakpoints.values.md}px) {
        margin-bottom: 16px;
      }
    }
    .MuiInputBase-root, .react-datepicker-wrapper input{
      background-color: ${theme.colors.INPUT_BACKGROUND}
    }
  }

  .MuiAvatar-root {
    width: 30px;
    height: auto;
  }
  .tooltiptext {
    top: 57px;
    right: 0;
    width: 50px;
    font-size: 14px;
  }
  .udcheckWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    flex: 0 0 90%;
    padding: 1.5% 0;
    .MuiFormControlLabel-root {
      margin-left: 0;
      .PrivateSwitchBase-root-362 {
        padding-left: 0;
      }
    }
  }
  .udcheckWrapper {
    @media (max-width: ${theme.breakpoints.values.smd}px) {
      flex-direction: column;
    }
    .MuiFormControlLabel-root {
      min-width: 203px;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        min-width: 150px;
      }
      span.MuiButtonBase-root {
        padding-left: 0;
      }
      .MuiFormControlLabel-label {
        font-size: 14px !important;
        @media (max-width: ${theme.breakpoints.values.md}px) {
          font-size: 12px !important;
        }
      }
    }
  }
  .exportWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    flex: 0 0 10%;
     
    justify-content: flex-end;
    .exportContainer .exportWrapper {
      min-width: 130px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        min-width: 130px;
      }
    }
  }
  `}
`;

const InfoContainer = styled(Box)`
  ${({ theme }) => `

  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 2%;
  padding-left: 2%;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    justify-content: flex-start;
  }
  .seperator {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    flex: 0 0 20%;
    justify-content: flex-start;
    align-self: center;
    background-color: ${theme.colors.HEADER_COLOR};
    padding: 20px 0;
    margin: 0;
    margin-right: 2%;
    padding-right: 5px;
    p:first-child {
      color: ${theme.colors.LIST_TEXT};
      font-size: ${window?.$environment?.clutchNewUI ? '12px' : '10px'};
      font-weight: ${window?.$environment?.clutchNewUI ? 'bold' : '600'};
      letter-spacing: 1px;
      line-height: 12px;
      text-transform: uppercase;
      order: 2;
    }
    p:last-child {
      color: ${theme.colors.PRIMARY_CONTRAST};
      font-size: ${window?.$environment?.clutchNewUI ? '40px' : '30px'};
      font-weight: ${window?.$environment?.clutchNewUI ? 'bold' : '600'};
      padding-left: 20px;
      padding-right: 10px;
      border-left: 5px solid ${theme.colors.SECONDARY_MAIN};
      line-height: 31px;
    }
  }
  p {
    text-transform: capitalize;
    align-self: center;
    color: ${(theme.palette.primary || {}).contrastText ||
    theme.colors.PRIMARY_CONTRAST
    };
    // :last-child {
    //   color: #ffffff;
    //   font-family: "Open Sans";
    //   font-size: 33px;
    //   font-weight: 600;
    //   order: 1;
    // }
  }
  `}
`;

const ToolsContainer = styled(Box)`
  ${({ theme, selectedindex }) => `

  position: absolute;
  background: ${(theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
  border: solid 2px
    ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
  top: ${selectedindex ? selectedindex + 'px' : '280px'};
  right: 25px;
  li {
    list-style: none;
  }
  a {
    color: ${(theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    font-size: 20px;
  }
  .ToolsOptionClose {
    color: ${(theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    position: absolute;
    margin-top: 10px;
    margin-right: 8px;
  }

  ul {
    margin-top: 20px;
    width: 175px;
    height: 25px;
    padding-inline-start: 25px;
  }
  `}
`;

const UdLists = styled(Box)`
  .MuiTable-root {
    border: 0;
  }
  .MuiTableRow-root,
  .MuiTableRow-root:hover {
    background-color: colors.BLACK;
  }
  .MuiTableCell-head {
    border-bottom: none;
  }
  .MuiInput-root {
    height: 34px;
    padding: 0;
  }
  // .MuiInputBase-input {
  //   padding: 0;
  // }
  .css-1wa3eu0-placeholder {
    font-size: 16px;
    top: 43%;
  }
  .select__control--is-focused,
  .css-8r3dx3-control {
    height: 34px;
    min-height: 34px !important;
    max-height: 34px;
    overflow: hidden !important;
  }
  .select__value-container {
    padding: 0;
    height: 27px;
  }
  .MuiTableCell-root {
    padding-top: 8px;
  }
`;
const Arrowdown = styled(Box)`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  justify-content: space-between;
  span {
    width: 90%;
    padding-left: 35px;
  }
  .MuiAvatar-root {
    width: 20px;
    height: 18px !important;
  }
  .MuiGrid-root {
    width: 6%;
    position: absolute;
    padding: 1px;
    justify-content: center;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.16);
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        left: 18px;
      }
    img {
      width: 10px;
      height: 7px;
    }
  }
  .udlogExpandedtable{
    position: absolute;
    top: 36.5px;
    left: 0;
    z-index: 1;
    form {
      padding: 1px 2%;
    }
    button.MuiButtonBase-root {
      margin-top: 9px;
      font-size: 20px;
      height: 39px;
    }
  }
`;
const UdlogTable = styled(Box)`
${({ theme }) => `
  padding: 0 2%;
  .MuiTableRow-head .MuiTableCell-head:first-child {
    // padding-left: 50px !important;
  }
  .MuiTableBody-root .MuiTableRow-root .noData {
    display: table-cell !important;
    text-align: center;
  }
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:last-child {
    padding-left: 2px !important;
    display: flex;
    a:first-child {
      padding-right: 11px;
    }
  }
  table tbody tr:nth-child(even):hover {
    background: ${window?.$environment.clutchNewUI || window?.$environment.WHITE_THEME ? '#fff' : '#222639'} !important;
  }
  // .MuiTableBody-root .MuiTableRow-root:hover {
  //   border: 0 !important;
  // }
  .MuiTableBody-root tr:nth-child(even) .floatingudTable {
    input,
    .select__single-value,
    .select__control,
    .MuiTableRow-head {
      background: ${window?.$environment.clutchNewUI || window?.$environment.WHITE_THEME ? '#fff' : '#222639'} !important;
    }
  }
  .floatingudTable{
    .MuiInputBase-input,
    .react-datepicker-wrapper input,
    .css-8r3dx3-control,
    .css-q5i2ns-control,
    .react-select__menu-list,
    .css-1wmipuf-option,
    .css-1irrqr3-option,
    .css-1hys26h-singleValue,
    .css-1ebjif1-option,
    .react-select__menu-list:active,
    .css-1wmipuf-option:active,
    .css-1ebjif1-option:active,
    .css-kpwi51-option:active,
    .css-kpwi51-option,
    .css-1wmipuf-option{
        background-color: ${theme.colors.INPUT_BACKGROUND} !important;
    }
    .MuiTableBody-root .MuiTableCell-root{
      font-weight: 400;
    }
    .MuiInputBase-root{
      ${window?.$environment?.clutchNewUI && `border: none;`}
    }
    input{
      ${window?.$environment?.clutchNewUI && `border: 1px solid #ccc;`}
    }

  }
  .MuiGrid-root + div {
    position: absolute;
  }
  .MuiTableCell-alignCenter .iconContainer .MuiAvatar-root {
    width: 20px !important;
    height: auto !important;
  }
`}
`;
const UnidentifiedSection = styled.div`
  ${({ theme }) => `
  .floatingudTable::-webkit-scrollbar {
    display: none;
  }
  .floatingudTable {
    // display: block;
    // position: absolute;
    // min-width: 1200px;
    // left: 10px;
    // left: 20px;
    // overflow: scroll;
    // z-index: 1;
    // width: 728px;
    .saveButton{
      position: absolute;
      right: 0;
    }
    .footer{
      display: flex;
    }
    .MuiTableRow-head :first-child.MuiTableCell-head {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        padding-left: 0 !important;
      }
    }
    .MuiTableBody-root tr:nth-child(even),
    .MuiTableBody-root tr:nth-child(odd) {
      background: ${window.$environment?.clutchNewUI ? '#fff' : 'transparent'} !important;
    }
    .select__menu {
      height: 100px;
      overflow: auto;
      .select__menu-list {
        height: 110px;
        max-height: 100%;
      }
    }
    .MuiButton-label {
      padding-left: 0 !important;
    }
    .MuiTableBody-root .MuiTableCell-root {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      font-size: 12px !important;
    }
    .MuiTableRow-root:hover {
      border: 0 !important;
    }
    .MuiTableBody-root .MuiTableCell-root{
      .MuiInputBase-root, .select__control{
        background-color: ${theme.colors.INPUT_BACKGROUND} !important;
      }
    }
    .MuiTableBody-root .MuiTableCell-root:nth-child(3){
      .MuiInputBase-root{
        border: 0 !important;
        margin-bottom: 0;
        height: auto;
        .MuiInputBase-input{
          padding: 0;
          padding-left: 5px;
          color: #fff;
        }
      }
    }
    .select__control { 
      min-height: 34px !important;
      position: relative;
      font-size: 12px;
      border-radius: 3px;
      margin-bottom: 0;
      ${window?.$environment?.clutchNewUI && `border: 1px solid ${theme.colors.INPUT_BORDER};`}
    }
    .select__placeholder {
      font-size: 12px;
    }
    .MuiInputBase-root{
    ${window.$environment.clutchNewUI && `border-color: ${theme.colors.INPUT_BORDER};`}
    }
  .MuiTableRow-head {
    border-bottom: 0 !important;
  }
    .example::-webkit-scrollbar {
      display: none;
    }
    // @media (min-width: ${theme.breakpoints.values.xl}px) {
    //   width: 965px;
    // }
    .MuiTableRow-head th {
      white-space: nowrap;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      border-bottom: 0;
      color: #748aa1;
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 400;
      text-transform: capitalize;
    }
    // @media (min-width: ${theme.breakpoints.values.xl}px) {
    //   input[name='location'] {
    //     max-width: 145px;
    //   }
    //   input[name='note'] {
    //     max-width: 180px;
    //   }
    //   .MuiTableBody-root .MuiTableRow-root td:first-child,
    //   .MuiTableBody-root .MuiTableRow-root td:nth-child(2) {
    //     min-width: 90px;
    //   }
    // }

    // @media (min-width: ${theme.breakpoints.values.xl + 1}px) {
    //   left: -166px;
    // }
    input {
      font-size: 12px;
    }
    .MuiTableCell-body .MuiBox-root {
      margin-bottom: 0;
      .MuiBox-root {
        flex: 0 0 100%;
      }
    }
    .Selectclass {
      @media (min-width: ${theme.breakpoints.values.xl + 1}px) {
        min-width: 156px;
      }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        min-width: 118px;
      }
      .select--is-disabled {
        opacity: 0.5;
      }
    }
    #button {
      margin-top: 9px;
    }
  }
  @media (min-width: ${theme.breakpoints.values.xl + 1}px) {
    position: relative;
    width: 100%;
  }
  `}
`;

const Content = styled(Box)`
${({ theme }) => `
.goBack {
    position: absolute;
    top: 8px;
    left: 2%;
    z-index: 99999;
      svg {
      color: #ccc !important;
    }
  }
  height: 100%;
  .logwrapper > header{
    ${theme.colors.NEW_THEME ? `
    .Mui-selected {
      :hover{
        color: #fff;
        .MuiTab-wrapper {
          color: #fff;
        }
      }
    }
    ` : ''}
  }
  .logwrapper {
    // overflow: hidden;
    height: 100%;
    display: flex;
    header.MuiAppBar-positionStatic {
      width: 16%;
      border-right: 1px solid ${theme.colors.SECONDARY_BORDER};
      padding-top: 3%;
    }
    div[role='tabpanel'].MuiTypography-root {
      width: 85%;
    }
    .MuiTab-wrapper {
      align-items: normal;
      text-align: left;
    }
    .MuiButtonBase-root.MuiTab-root {
      border-right: 0;
      padding-left: 8%;
      text-transform: capitalize !important;
      border-bottom: 1px solid ${theme.colors.SECONDARY_BORDER};
      background: ${theme.colors.PRIMARY_MAIN};
      margin-right: 0 !important;
      :hover {
        border-bottom: 1px solid ${theme.colors.SECONDARY_BORDER} !important;
        color: #fff;
      }
    }
    .MuiTabs-flexContainer .Mui-selected {
      background-color: ${theme.colors.BACKGROUND_MENU};
      border-bottom: 0 !important;
      font-weight: 700;
      color: #fff;
    }
  }
`}
`;
const Styled = {
  ExportWrapper,
  Header,
  InfoContainer,
  ToolsContainer,
  UdLists,
  Arrowdown,
  UdlogTable,
  UnidentifiedSection,
  Content
};

export default Styled;
