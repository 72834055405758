import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  ${({ theme }) => `
  .MuiTypography-body1 {
    font-size: 20px !important;
  }
  .MuiSvgIcon-root {
    border: 2px solid ${theme.colors.LIGHT_BORDER};
    background: colors.BLACK;
  }

  .MuiIconButton-root:hover {
    background-color: transparent;
  }
    .checkBoxDisabled {
       cursor: not-allowed;
    }
  .normalCheckbox {
    padding-right: 9px;
    .Checkbox {
      top: 3px;
    }
    label {
      font-size: 18px;
      padding-left: 12px;
    }
  }
  .normalCheckbox svg {
    width: 26px;
    height: 26px;
  }
  .Checkbox .tick {
    stroke-dasharray: 21;
  }
  .Checkbox.checked .tick {
    stroke-dashoffset: 0;
  }
  .Checkbox.unchecked .tick {
    opacity: 0;
  }
  .normalCheckbox svg rect {
    rx: 0;
    ry: 0;
  }
  .normalCheckbox svg path {
    stroke: #fff;
  }
  .normalCheckbox .Checkbox.checked .square {
    fill-opacity: 0;
  }
  .normalCheckbox .unchecked.disabledCheck .square {
    fill-opacity: 1 !important;
  }
  .normalCheckbox .Checkbox.unchecked .square {
    fill-opacity: 0;
  }
  .normalCheckbox .unchecked.disabledCheck + label {
    color: #373737 !important;
  }
  `}
`;

export default CheckboxWrapper;
