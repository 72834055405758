import { checkIsEmbededPage } from '@/utils/utils';
import styled from 'styled-components';
import Parent from './style';

const HeaderContainer = styled(Parent.HeaderContainer)`
${({ theme, withBackground, withBackgroundPolicy }) => `
  #container {
    padding-top: 2%;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      padding-bottom: 2%;
      height: 52px;
    }
    h1 {
      padding-left: 2%;
      font-family: ${theme.palette.fontFamily};
      font-size: 25px;
      text-transform: capitalize !important;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        flex: 0 0 auto;
      }
    }
    ${checkIsEmbededPage() ? `
        display: flex;
        flex-direction: row;
        color: ${theme.palette.primary.contrastText};
        justify-content: center;
        align-items: center;
    ` : ``}
  }
  `}`;
const Styled = {
  HeaderContainer
  
};
export default Styled;
