let eldX_reseller = window.location.pathname.split("/")[1] || sessionStorage.getItem("eldX_reseller")
export default {
  'dev.gorillasafety.com': {
    APP_LAYOUT: 'NewUi',
    BASE_URL: 'https://staging.gorillasafety.com',
    BE_BASE_URL: 'https://staging.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'gs',
    SWITCH_APP_LAYOUT: 'OldUi',
    EMBED_URL: '/embed',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'support@gorillasafety.com',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'staging.gorillasafety.com': {
    APP_LAYOUT: 'NewUi',
    BASE_URL: 'https://staging.gorillasafety.com',
    BASE_CAMERA_URL: 'https://staging.gorillasafety.com/api/waylens-client/',
    CAMERA_TOKEN: '9cf11b4ccf46a304a871ad80ec360a4fde57c12820953b61732f287f10e833e9',
    BE_BASE_URL: 'https://staging.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'gs',
    SWITCH_APP_LAYOUT: 'OldUi',
    EMBED_URL: '/embed',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'support@gorillasafety.com',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },

  'clutch.gorillasafety.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://dev.clutch.com',
    BE_BASE_URL: 'https://clutch.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'clutch',
    GMAP_KEY: 'AIzaSyBnjFoOyqXkkS_y-aXp7GFWsnKfo8Z_Hng',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'emdev.gorillasafety.org': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://dev.gorillasafety.com',
    BE_BASE_URL: 'https://clutch.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'em',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'spireon.gorillasafety.org': {
    APP_LAYOUT: 'SpireonNew',
    BASE_URL: 'https://spireon.gorillasafety.com/',
    BE_BASE_URL: 'https://dev.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'spireon',
    SWITCH_APP_LAYOUT: 'SpireonOld',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'tp.gorillasafety.org': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://dev.gorillasafety.com',
    BE_BASE_URL: 'https://clutch.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'em',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'todev.gorillasafety.org': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'trackondev.gorillasafety.org',
    BE_BASE_URL: 'trackondev.gorillasafety.org/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'em',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    REG_LINK: 'https://staging.gorillasafety.com/registration/step1',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'eldmandate.gorillasafety.com': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://eldmandate.gorillasafety.com',
    BE_BASE_URL: 'https://eldmandate.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'em',
    GMAP_KEY: 'AIzaSyCC8c21znoJly89haW74TPwR6QoliU7vzk',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'truckerpath.gorillasafety.com': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://truckerpath.gorillasafety.com',
    BE_BASE_URL: 'https://truckerpath.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'tp',
    GMAP_KEY: 'AIzaSyDiinAX9MMRaMZL_SdXt_GAa7qObWKsB7c',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    ZENDESK_KEY: '7fefde19-a018-44b2-b68f-f3b12af5835f',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'trackoneld.gorillasafety.com': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://trackoneld.gorillasafety.com/',
    BE_BASE_URL: 'https://trackoneld.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'trackon',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    REG_LINK: 'https://staging.gorillasafety.com/registration/step1',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'compliance-stage.spireon.com': {
    APP_LAYOUT: 'SpireonNew',
    BASE_URL: 'https://compliance-stage.spireon.com',
    BE_BASE_URL: 'https://compliance-stage.spireon.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'spireon',
    SWITCH_APP_LAYOUT: 'SpireonOld',
    GMAP_KEY: 'AIzaSyDKNSVf3qP5_Ck_SQ_BmzHTrocqO2XhUXA',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'spireoneld.gorillasafety.com': {
    APP_LAYOUT: 'SpireonNew',
    BASE_URL: 'https://spireoneld.gorillasafety.com',
    BE_BASE_URL: 'https://spireoneld.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'spireon',
    SWITCH_APP_LAYOUT: 'SpireonOld',
    GMAP_KEY: 'AIzaSyDKNSVf3qP5_Ck_SQ_BmzHTrocqO2XhUXA',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'compliance-stage.fleetlocate.com': {
    APP_LAYOUT: 'SpireonNew',
    BASE_URL: 'https://compliance-stage.fleetlocate.com',
    BE_BASE_URL: 'https://compliance-stage.fleetlocate.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'spireon',
    SWITCH_APP_LAYOUT: 'SpireonOld',
    GMAP_KEY: 'AIzaSyDKNSVf3qP5_Ck_SQ_BmzHTrocqO2XhUXA',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'compliance-stage.gpsservicenetwork.com': {
    APP_LAYOUT: 'SpireonNew',
    BASE_URL: 'https://compliance-stage.gpsservicenetwork.com',
    BE_BASE_URL: 'https://compliance-stage.gpsservicenetwork.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'spireon',
    SWITCH_APP_LAYOUT: 'SpireonOld',
    GMAP_KEY: 'AIzaSyDKNSVf3qP5_Ck_SQ_BmzHTrocqO2XhUXA',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'cyntrx.gorillasafety.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://cyntrx.gorillasafety.com',
    BE_BASE_URL: 'https://cyntrx.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'cyntrx',
    GMAP_KEY: 'AIzaSyBnjFoOyqXkkS_y-aXp7GFWsnKfo8Z_Hng',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'cyntrxdvir.gorillasafety.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://cyntrxdvir.gorillasafety.com ',
    BE_BASE_URL: 'https://cyntrxdvir.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'edvir',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'webfleet.gorillasafety.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://webfleet.gorillasafety.com',
    BE_BASE_URL: 'https://webfleet.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    EMBED_URL: '/embed',
    SERVICE_PROVIDER: 'webfleet',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    clutchNewUI: true,
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'risktheory.gorillasafety.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://risktheory.gorillasafety.com',
    BE_BASE_URL: 'https://risktheory.gorillasafety.com/api',
    BASE_CAMERA_URL: 'https://risktheory.gorillasafety.com/api/waylens-client/',
    CAMERA_TOKEN: '9cf11b4ccf46a304a871ad80ec360a4fde57c12820953b61732f287f10e833e9',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'riskTheory',
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'allwaystrack.gorillasafety.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://allwaystrack.gorillasafety.com',
    BE_BASE_URL: 'https://allwaystrack.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'at',
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'eldxstg.gorillasafety.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://eldxstg.gorillasafety.com',
    BE_BASE_URL: `https://eldxstg.gorillasafety.com/api`,
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'eldx',
    WHITE_THEME: true,
    RESELLER: '/' + eldX_reseller,
    S3_URL: `https://eldx-reseller-staging.s3.amazonaws.com/${eldX_reseller}`,
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'simplex-stg.gorillasafety.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://simplex-stg.gorillasafety.com',
    BE_BASE_URL: 'https://simplex-stg.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'simplex',
    WHITE_THEME: true,
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'ELDSupport@simplexgroup.net',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'azuga.gorillasafety.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://azuga.gorillasafety.com',
    BE_BASE_URL: 'https://azuga.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'azuga',
    WHITE_THEME: true,
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p,',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'www.staging-eld.webfleet.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://www.staging-eld.webfleet.com',
    BE_BASE_URL: 'https://www.staging-eld.webfleet.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'webfleet',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    clutchNewUI: true,
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'canadastg.gorillasafety.org': {
    APP_LAYOUT: 'NewUi',
    BASE_URL: 'https://canadastg.gorillasafety.org',
    BE_BASE_URL: 'https://canadastg.gorillasafety.org/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'gs',
    SWITCH_APP_LAYOUT: 'OldUi',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'support@gorillasafety.com',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'fleettrack.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://fleettrack.gorillasafety.org',
    BE_BASE_URL: 'https://fleettrack.gorillasafety.org/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    WHITE_THEME: true,
    SERVICE_PROVIDER: 'ft',
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'support@lbtechnology.com',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'trackit.gorillasafety.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://trackit.gorillasafety.com',
    BE_BASE_URL: 'https://trackit.gorillasafety.com/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'trackit',
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'alert@trakitgps.com',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'hcss.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://hcss.gorillasafety.org',
    BE_BASE_URL: 'https://hcss.gorillasafety.org/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'hcss',
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'support@hcss.com',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'patrioteld.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://patrioteld.gorillasafety.org',
    BE_BASE_URL: 'https://patrioteld.gorillasafety.org/api',
    EMBED_URL: '/embed',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'patriot',
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'support@patrioteld.com ',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'nauto.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    SERVICE_PROVIDER: 'nauto',
    BASE_URL: 'https://nauto.gorillasafety.org',
    BE_BASE_URL: 'https://nauto.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    EMBED_URL: '/embed',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'support@nauto.com',
    BASE_CAMERA_URL: 'https://nauto.gorillasafety.org/api/waylens-client/',
    CAMERA_TOKEN: '9cf11b4ccf46a304a871ad80ec360a4fde57c12820953b61732f287f10e833e9',
  },
};